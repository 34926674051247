body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.icon-whatsapp{
  color: #25d366;
}

.text-nav-whatsapp{
  color: #075e54;
}

.phone-nav-whatsapp{
  color:#25d366
}

.menu-custom{
  height: 50px;
  background-color:#BE6837 ;
}

.custom-item-nav{
  color: white !important;
}


.button-custom {
  color: #BE6837 !important;
  border-color: #BE6837 !important;
}

.button-custom:hover{
  color:#FFEDEA !important;
  background-color: #BE6837 !important;
}

.about-section{
  background-color: #F6F1E7;
}

.team-section{
  background-color: white;
}

.custom-footer{
  background-color: #BE6837;
}

.custom-image-services{
  height: 400px;
  border-radius: 100% 0% 100% 0% / 0% 67% 33% 100%;
}

.estrutura-section {
  background-color: #F6F1E7;
}
